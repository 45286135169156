import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import { selectIsUserAuthenticated } from '@/features/auth/authSlice';
import withAuth from '@/utils/withAuth';

const CspAppInit = () => {
  const router = useRouter();
  const isUserAuthenticated = useAppSelector(selectIsUserAuthenticated);
  useEffect(() => {
    if (isUserAuthenticated) {
      router.replace(`/summary-dashboard`);
    }
  }, [isUserAuthenticated]);
  return null;
};

export default withAuth(CspAppInit);
