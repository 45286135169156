import React, { useEffect, useState } from 'react';

import { NextPage } from 'next';
import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import { selectTokenExpiry } from '@/features/auth/authSlice';
import { selectUserDetails } from '@/features/user/userSlice';
import { triggerLogout } from '@/services/logoutUtil';
import {
  handleRedirectionForCspsDisabledStudyoverseas,
  handleRedirectionForCspsEnabledStudyoverseas,
} from '@/utils/loginUtils';

const withAuth = (Page: NextPage) => {
  const AuthenticatedComponent = () => {
    const router = useRouter();
    const profileData = useAppSelector((state) => selectUserDetails(state));
    const isTokenExpired = useAppSelector(selectTokenExpiry);
    const [settingUp, setSettingUp] = useState<boolean>(false);
    const [isStudentLogin, setIsStudentLogin] = useState<boolean>(false);
    const redirectionUrlForCspsEnabledStudyoverseas =
      handleRedirectionForCspsEnabledStudyoverseas();
    const redirectionUrlForCspsDisabledStudyoverseas =
      handleRedirectionForCspsDisabledStudyoverseas();

    useEffect(() => {
      if (!window) return;
      if (isTokenExpired) {
        triggerLogout(true);
        return;
      }
      // const token = getCookie(null, `token`);
      // const auth = new AuthToken(token);
      // const isStudentLoggedIn = validateIfStudentIsLoggedIn(auth);

      // if (isLoggedOutUserEnteringStudyOverseas()) {
      //   window?.location?.replace(`${window?.location?.origin}/ielts`);
      //   return;
      // }
      // if (auth.isExpired) {
      //   if (
      //     isStudentLoggedIn ||
      //     window?.location?.hostname?.includes(`studyoverseas.ai`)
      //   ) {
      //     router?.replace(`/student-login`);
      //   } else {
      //     router.replace(`/login`);
      //   }
      // } else

      if (
        profileData &&
        router.route === `/manage-student-registration-form` &&
        !profileData?.permissions?.includes(`ADMIN`)
      ) {
        router.replace(`/`);
      } else if (redirectionUrlForCspsEnabledStudyoverseas) {
        window?.location?.replace(redirectionUrlForCspsEnabledStudyoverseas);
      } else if (redirectionUrlForCspsDisabledStudyoverseas) {
        window?.location?.replace(redirectionUrlForCspsDisabledStudyoverseas);
      } else if (
        profileData?.permissions?.includes(`STUDENT`) &&
        !router?.route?.startsWith(`/test-prep/mock-test`)
      ) {
        setIsStudentLogin(true);
      }
      setSettingUp(false);
    }, [window, profileData, isTokenExpired]);

    if (isStudentLogin) {
      router.replace(`/test-prep/mock-test`);
      return null;
    }

    return !settingUp && !isStudentLogin ? <Page /> : null;
  };
  AuthenticatedComponent.displayName = Page.displayName || `NA`;
  return AuthenticatedComponent;
};

export default withAuth;
